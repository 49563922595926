import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// @mui
// import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import { useResponsive } from 'src/hooks/use-responsive';
// routes
import { RouterLink } from 'src/routes/components';
import { useTheme } from '@emotion/react';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const theme = useTheme();

  const mdUp = useResponsive('up', 'md');

  // const PRIMARY_LIGHT = theme.palette.primary.light;

  // const PRIMARY_MAIN = theme.palette.primary.main;

  // const PRIMARY_DARK = theme.palette.primary.dark;

  // OR using local (public folder)
  // -------------------------------------------------------
  // const logo = (
  //   <Box
  //     component="img"
  //     src="/logo/logo_single.svg" => your path
  //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
  //   />
  // );

  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: 32,
        height: 32,
        margin: 'auto',
        // marginTop: !mdUp ? 0 : 2.3,
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >

      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42.41 49.25">
        <defs>
          <linearGradient id="logo-gradient" x1="50%" y1="0%" x2="50%" y2="100%" >

            <stop offset="0%" stopColor="#7A5FFF">
              <animate attributeName="stop-color" values={`${theme.palette.primary.main}; ${theme.palette.warning.main}; ${theme.palette.primary.main}`} dur="4s" repeatCount="indefinite" />
            </stop>

            <stop offset="100%" stopColor="#01FF89">
              <animate attributeName="stop-color" values={`${theme.palette.primary.main}; ${theme.palette.warning.main}; ${theme.palette.primary.main}`} dur="4s" repeatCount="indefinite" />
            </stop>

          </linearGradient>

        </defs>
        <rect fillOpacity="0" width="40.49" height="40.39" />
        <rect fill="url('#logo-gradient')" x="5.82" y="4.77" width="11.39" height="30.54" />
        <path fill="url('#logo-gradient')" d="M21.62,4.77h4.15a7.52,7.52,0,0,1,7.52,7.52v.39a7.52,7.52,0,0,1-7.52,7.52H21.62a0,0,0,0,1,0,0V4.77A0,0,0,0,1,21.62,4.77Z" />
        <polygon fill="url('#logo-gradient')" points="21.61 20.32 21.61 35.31 37.33 35.31 21.61 20.32" />
        <path fill={theme.palette.primary.main} d="M4,49.52V43H6.49a1.48,1.48,0,0,1,.31.07,1.94,1.94,0,0,1,1.29,1,2.21,2.21,0,0,1,.26,1,1.9,1.9,0,0,1-.56,1.37,1.82,1.82,0,0,1-1.34.58l1.81,2.42h-.8L5.29,46.58H6l.26,0a2.2,2.2,0,0,0,.6-.16,1.38,1.38,0,0,0,.61-.53,1.44,1.44,0,0,0,.22-.78,1.4,1.4,0,0,0-.16-.66,1.24,1.24,0,0,0-.43-.5,1.71,1.71,0,0,0-.7-.27,6.3,6.3,0,0,0-.75,0h-1v5.9Z" transform="translate(-3.79 -0.37)" />
        <path fill={theme.palette.primary.main} d="M10,49.52V43H13.5v.58H10.63V46H13.4v.58H10.63v2.39H13.5v.59Z" transform="translate(-3.79 -0.37)" />
        <path fill={theme.palette.primary.main} d="M14.8,49.52,17.57,43h.71L21,49.52h-.69l-.93-2.18h-3l-.93,2.18Zm1.87-2.77h2.48l-1.22-3Z" transform="translate(-3.79 -0.37)" />
        <path fill={theme.palette.primary.main} d="M22.51,49.52V43h.65v5.89h2.65v.59Z" transform="translate(-3.79 -0.37)" />
        <path fill={theme.palette.primary.main} d="M28.12,49.52v-5.9H26.64V43h3.58v.58H28.76v5.9Z" transform="translate(-3.79 -0.37)" />
        <path fill={theme.palette.primary.main} d="M34.77,42.93a3.33,3.33,0,0,1,2.39,1,3.14,3.14,0,0,1,1,2.35,3.4,3.4,0,0,1-3.4,3.38,3.3,3.3,0,0,1-2.37-1,3.15,3.15,0,0,1-1-2.36,3.2,3.2,0,0,1,1-2.38A3.26,3.26,0,0,1,34.77,42.93Zm0,.62A2.75,2.75,0,0,0,32,46.3a2.54,2.54,0,0,0,.82,1.9,2.65,2.65,0,0,0,1.93.8,2.73,2.73,0,0,0,2.74-2.75,2.56,2.56,0,0,0-.81-1.9A2.69,2.69,0,0,0,34.78,43.55Z" transform="translate(-3.79 -0.37)" />
        <path fill={theme.palette.primary.main} d="M39.93,49.52V43h2.49a1.48,1.48,0,0,1,.31.07,1.74,1.74,0,0,1,.55.21,1.84,1.84,0,0,1,.74.75,2.21,2.21,0,0,1,.26,1,1.9,1.9,0,0,1-.56,1.37,1.8,1.8,0,0,1-1.33.58l1.8,2.42h-.8l-2.17-2.94h.72l.27,0a2.41,2.41,0,0,0,.6-.16,1.43,1.43,0,0,0,.61-.53,1.47,1.47,0,0,0,.06-1.44,1.34,1.34,0,0,0-.44-.5,1.66,1.66,0,0,0-.7-.27,6.16,6.16,0,0,0-.75,0h-1v5.9Z" transform="translate(-3.79 -0.37)" />
        <path fill={theme.palette.primary.main} d="M45,44.25A1.22,1.22,0,1,1,46.21,43,1.22,1.22,0,0,1,45,44.25ZM45,42a1,1,0,1,0,1,1A1,1,0,0,0,45,42Z" transform="translate(-3.79 -0.37)" />
        <path fill={theme.palette.primary.main} d="M45.36,43.72,45,43.24h-.3v.48h-.23V42.35h.6a.66.66,0,0,1,.21,0,.6.6,0,0,1,.16.08.45.45,0,0,1,.1.14.58.58,0,0,1,0,.18.41.41,0,0,1,0,.16.75.75,0,0,1-.07.12.27.27,0,0,1-.1.08.31.31,0,0,1-.13.06l.37.52Zm-.08-1.1a.33.33,0,0,0-.21,0h-.35V43h.35a.33.33,0,0,0,.21-.06.22.22,0,0,0,.07-.17A.21.21,0,0,0,45.28,42.62Z" transform="translate(-3.79 -0.37)" />
      </svg>
    </Box>
  );

  if (disabledLink) {
    return logo;
  }

  console.log('disabledLink => ', disabledLink)

  return (
    <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default Logo;
