import PropTypes from 'prop-types';
import { m } from 'framer-motion';
// @mui
import IconButton from '@mui/material/IconButton';
// components
import Iconify from 'src/components/iconify';
import { varHover } from 'src/components/animate';
import { useGetPageQuery, useLazyPostTrackQuery } from 'src/redux/services/salesrush/endpoints';
import { formatPhoneNumber } from 'src/utils/phone-format';
import { useState } from 'react';
import { Button } from '@mui/material';
import { grey } from '@mui/material/colors';
import moment from 'moment';

// ----------------------------------------------------------------------

export default function PhoneButton({ sx }) {

  const [subdomain] = useState(window.location.host.split("."));

  const { data, isLoading } = useGetPageQuery(subdomain[0]);

  const [trigger] = useLazyPostTrackQuery();


  const track = async (data_track) => {

    const subdomain = window.location.host.split(".");

    const data_ = {
      url: data_track,
      page: data_track,
      subdomain: subdomain[0],
      f_date: moment().format('MM-YYYY'),
      date: moment().format()
    };

    console.log(data_)

    await trigger(data_).unwrap();
  }

  return (

    // <Button
    //   sx={{
    //     width: 20,
    //     color: grey[500]
    //   }}

    //   size="small"
    //   variant="text"
    //   href={`tel:${formatPhoneNumber(data?.user?.cell_phone_number)}`}
    // >
    //   <Iconify icon="solar:phone-bold" width={24} />
    // </Button>
    <IconButton
      onClick={() => {
        track('Contact Direct')
        window.location.href = `tel:${formatPhoneNumber(data?.user?.cell_phone_number)}`
      }}
      whileTap="tap"
      whileHover="hover"
      variants={varHover(1.05)}
      aria-label="settings"
      // href={`tel:${formatPhoneNumber(data?.user?.cell_phone_number)}`}
      sx={{
        width: 40,
        height: 40,
      }}
    >
      <Iconify icon="solar:phone-bold" width={24} />
    </IconButton>

  );
}

PhoneButton.propTypes = {
  sx: PropTypes.object,
};
