import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { AuthConsumer } from 'src/auth/context/jwt';
// layouts

import MainLayout from 'src/layouts/main';
import SimpleLayout from 'src/layouts/simple';
import CompactLayout from 'src/layouts/compact';
// components
import { SplashScreen } from 'src/components/loading-screen';
import { paths } from '../paths';

// ----------------------------------------------------------------------

export const HomePage = lazy(() => import('src/pages/home'));
const NewHomeStepOnePage = lazy(() => import('src/pages/new-home/new-home-step-one-page'));
const FormWizardQuestionsPage = lazy(() => import('src/pages/form-wizard/form-wizard-questions'));
const HomeHuntPage = lazy(() => import('src/pages/home-hunt/home-hunt-page'));
const LandingConfirmation = lazy(() => import('src/template/landing-confirmation/landing-confirmation'));
const FormTemplate = lazy(() => import('src/template/form/form-template'));
const RefinancePage = lazy(() => import('src/pages/refinance/refinance-page'));
const ListingsPage = lazy(() => import('src/pages/listings/listings-page'));
const Page500 = lazy(() => import('src/pages/500'));
const Page403 = lazy(() => import('src/pages/403'));
const Page404 = lazy(() => import('src/pages/404'));
const FaqsPage = lazy(() => import('src/pages/faqs'));
const AboutPage = lazy(() => import('src/pages/about-us'));
const ContactPage = lazy(() => import('src/pages/contact-us'));
const PricingPage = lazy(() => import('src/pages/pricing'));
const PaymentPage = lazy(() => import('src/pages/payment'));
const ComingSoonPage = lazy(() => import('src/pages/coming-soon'));
const MaintenancePage = lazy(() => import('src/pages/maintenance'));
// PRODUCT
const ProductListPage = lazy(() => import('src/pages/product/list'));
const ProductDetailsPage = lazy(() => import('src/pages/product/details'));
const ProductCheckoutPage = lazy(() => import('src/pages/product/checkout'));
// BLOG
const PostListPage = lazy(() => import('src/pages/post/list'));
const PostDetailsPage = lazy(() => import('src/pages/post/details'));

// ----------------------------------------------------------------------

export const mainRoutes = [
  {
    element: (
      <AuthConsumer>
        <MainLayout>
          <Suspense fallback={<SplashScreen />}>
            <Outlet />
          </Suspense>
        </MainLayout>
      </AuthConsumer>
    ),
    children: [
      { path: 'confirmation', element: <LandingConfirmation /> },
      {
        path: paths.listings.homes,
        children: [
          { element: <ListingsPage />, index: true },
          { path: ':address/:listingId', element: <ListingsPage /> }
        ]
      },
      { path: 'about-us', element: <AboutPage /> },
      { path: 'contact-us', element: <ContactPage /> },
      { path: 'faqs', element: <FaqsPage /> },
      {
        path: 'product',
        children: [
          { element: <ProductListPage />, index: true },
          { path: 'list', element: <ProductListPage /> },
          { path: ':id', element: <ProductDetailsPage /> },
          { path: 'checkout', element: <ProductCheckoutPage /> },
        ],
      },
      {
        path: 'learn',
        children: [
          { element: <PostListPage />, index: true },
          { path: 'list', element: <PostListPage /> },
          { path: ':slug', element: <PostDetailsPage /> },
        ],
      },
    ],
  },
  {
    element: (
      <AuthConsumer>
        <SimpleLayout>
          <Suspense fallback={<SplashScreen />}>
            <Outlet />
          </Suspense>
        </SimpleLayout>
      </AuthConsumer>
    ),
    children: [
      { path: paths.newHome, element: <NewHomeStepOnePage /> },
      { path: paths.refinance, element: <RefinancePage /> },
      { path: paths.homeHunt, element: <HomeHuntPage /> },
      { path: 'pricing', element: <PricingPage /> },
      { path: 'payment', element: <PaymentPage /> },
      {
        path: paths.newHome,
        children: [
          { path: ':slug', element: <FormTemplate /> }
        ]
      },
      {
        path: paths.refinance,
        children: [
          { path: ':slug', element: <FormTemplate /> }
        ]
      },
      {
        path: 'landing',
        children: [
          { path: ':slug', element: <FormTemplate /> },
          { path: 'questions/:id/:type', element: <FormWizardQuestionsPage /> },
        ]
      }
    ],
  },
  {
    element: (
      <CompactLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </CompactLayout>
    ),
    children: [
      { path: 'coming-soon', element: <ComingSoonPage /> },
      { path: 'maintenance', element: <MaintenancePage /> },
      { path: '500', element: <Page500 /> },
      { path: '404', element: <Page404 /> },
      { path: '403', element: <Page403 /> },
      { path: 'reactivate', element: <Page403 /> },
    ],
  },
];
